import { type MetaFunction } from '@remix-run/node'
import {Link} from "@remix-run/react";

export const meta: MetaFunction = () => [{ title: 'refundr' }]
export default function Index() {
	return (
		<div>
			<div className="h-full">
				<div className="flex flex-col justify-center gap-8 mt-6">
					<div className="text-center">
						<h2 className="text-h2">Tax Consultants</h2>
						{/*<p className="mt-3 text-body-md text-muted-foreground">Glad you found us</p>*/}
					</div>
					<div className="container mt-4">
						<div
							className="mx-auto max-w-5xl rounded-xl bg-white p-8 font-mono font-bold text-gray-700 shadow-lg">
							Welcome. We are glad you found us. At our core, we're driven by a mission to alleviate the
							chaos of tax season for you and your customers.
							We strive to understand the intricate challenges you face during this critical period, and
							we're here to provide the support and tools you need to navigate through it.
						</div>
					</div>
					<div className="container">
						<div
							className="mx-auto max-w-5xl rounded-xl bg-white p-8 font-mono font-bold text-gray-700 shadow-lg mb-40">
							Our commitment to fostering trust, delivering efficiency, and ensuring that every client
							interaction is met with clarity and satisfaction.
							Together, let's empower both consultants and their customers to thrive amidst the
							complexity.
						</div>
					</div>
				</div>
			</div>
			<div className="mx-auto flex max-w-5xl justify-center border-t border-gray-500 p-5 text-center text-sm">
				<Link to="/privacy-policy">privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/terms">terms</Link>
				&nbsp;&nbsp;|&nbsp;&nbsp;&#169;&nbsp;copyright 2024
			</div>
		</div>
	)
}
